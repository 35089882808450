import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import BootstrapVue from 'bootstrap-vue';
// import VueConfirmDialog from 'vue-confirm-dialog'
// import VueSimpleAlert from "vue-simple-alert";
import router from "@/core/router/router";
import { store } from "@/core/store";




loadFonts()
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(store);

// app.use(BootstrapVue);
// app.use(IconsPlugin);




app.mount('#app');
