

export const SET_ESCOLHA = "setServico"


const actions = {
 

      set_escolha(context, value) {
        context.commit(SET_ESCOLHA,value)
      },

     
};
const getters = {};

const mutations = {
   
    [SET_ESCOLHA](state,value){
        state.escolha = value
    },
  
};

const state = {
escolha:0

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
